import pecanai from "../assets/pecanai.jpg";
import projectTwo from "../assets/Psix.JPG";
import P1 from "../assets/P1.JPG";


const projects = {
  1: {
    title: "Predicting Monthly Customer Revenue",
    image: pecanai,
    description: (
      <>
        <p>
        The Monthly Customer Revenue Prediction project leverages the advanced capabilities of Pecan AI to forecast the revenue generated by customers on a monthly basis. This project involves building and training an AI model that can accurately predict future revenue, helping businesses make informed decisions and strategize effectively
        </p>
      </>
    ),
    demo: "https://docs.google.com/presentation/d/1_uX4b-b8yVJGnp75pzxNc4VF0GX3mn3j-Q26Ikk3apI/edit?usp=sharing ",
  },
  2: {
    title: "NovacoffeeOrdersProject",
    image: projectTwo,
    description: (
      <>
        <p>
        The Customer Data Generator is a Python-based project designed to create realistic synthetic data for customers. This project can be particularly useful for testing and development purposes in data analysis, machine learning, and database management applications. The generator produces a diverse set of customer profiles with unique attributes, including names, emails, coffee preferences, and addresses, ensuring a robust dataset without repetitions.
        </p>
      </>
    ),
  
    demo: "https://docs.google.com/presentation/d/12qP2AmsMD3ZrVMLhCyStfYFSfUVLwCdMt6sHE9gGb3I/edit?usp=sharing",
  },

  3: {
    title: "Headstarter Fellowship",
    image: P1,
    description: (
      <>
        <p> This is a lnk to all the project I did during my time as a Headstarter fellow. </p>
      </>
    ),
   
    demo: "https://nova-64.github.io/landing-page/",
  },
 
};

export default projects;