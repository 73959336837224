import React, { Component } from "react";
import Nova from "../avatar/Nova.js";
import NovaMenu from "./NovaMenu.js";

export default class About extends Component {
  render() {
    return (
      <>
        <NovaMenu />
      </>
    );
  }
}